import React, { useState, useEffect } from "react";
import { DatePicker, Tag } from "antd";
import moment from "moment";

const DATE_FORMAT = "DD-MM-YYYY";

const MultiDateSelector = ({ value = [], onChange }) => {
    const [selectedDates, setSelectedDates] = useState(value || []);

    useEffect(() => {
        setSelectedDates(value || []);
    }, [value]); // Ensure form initial values are synced

    const handleDateChange = (date) => {
        if (!date) return;
        const newDate = moment(date); // Store moment object

        if (!selectedDates.some(d => d.isSame(newDate, "day"))) {
            const newDates = [...selectedDates, newDate];
            setSelectedDates(newDates);
            onChange(newDates); // Update AntD Form field with moment objects
        }
    };

    const handleRemoveDate = (dateToRemove) => {
        const newDates = selectedDates.filter(date => !date.isSame(dateToRemove, "day"));
        setSelectedDates(newDates);
        onChange(newDates); // Update AntD Form field after removal
    };

    return (
        <div>
            <DatePicker format={DATE_FORMAT} onChange={handleDateChange} />
            <div style={{ marginTop: 10 }}>
                {selectedDates.map(date => (
                    <Tag key={date.format(DATE_FORMAT)} closable onClose={() => handleRemoveDate(date)}>
                        {date.format(DATE_FORMAT)}
                    </Tag>
                ))}
            </div>
        </div>
    );
};

export default MultiDateSelector;
